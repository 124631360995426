<template>
  <div class="modal-overlay" @keydown="handleKeydown" tabindex="0">
    <div class="modal">
      <alert messageType="error" :message="errorMessage" @close="errorMessage = null" position="absolute" />
      <img class="close-img" src="../assets/close-icon.png" @click="close" />
      <h6>Reset wachtwoord</h6>
      <div v-on:keyup.enter="resetPassword()">
        <input class="form-control input" :class="{ 'is-invalid': $v.email.$error}" v-model="email" placeholder="Email" @click="showSucceeded=false, errorMessage = null"/>
        <div v-if="$v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">Email is vereist</span>
        </div>
      </div>
      <div>
        <button class="btn btn-secondary" @click="resetPassword()">Verstuur</button>
      </div>
      <label v-if="showSucceeded">Email is verzonden</label>
    </div>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import Alert from '../components/Alert.vue'

export default {
  name: 'ResetPasswordModal',
  components: { Alert },
  props: {
    username: {}
  },
  data () {
    return {
      email: this.username,
      errorMessage: null,
      showSucceeded: false
    }
  },
  validations: {
    email: { required }
  },
  mounted() {
    // a global event listener for "Esc" keypress
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    // removing the global event listener when the component is destroyed
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        // calls the 'close' event when the "Esc" key is pressed
        this.close();
      }
    },
    close() {
      this.$emit('close');
      this.showSucceeded = false;
      this.errorMessage = null;
      this.$v.$reset()
    },
    resetPassword () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if(this.email){
          const requestBody = {
            email : this.email,
          }
          this.$http.post(`/rest-auth/password/reset/`, requestBody).then(
            () => {
              this.email="";
              this.showSucceeded=true;
              this.errorMessage = null;
              this.$v.$reset();
            },
            (error) => {
              this.errorMessage = error.response.data.email[0]
            }
          )
        }
      }
    }
  }
}
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 300px;
  width: 500px;
  margin-top: 10%;
  margin-left: 35%;
  padding: 10px 60px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.close-img {
  margin: 0 0 0 400px;
  width: 25px;  
  cursor: pointer;
}

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 60px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 15px;
  width: 130px;
  max-width: 300px;
}

.label-text{
  color: var(--color-1);
  font-weight: bold;
}

.custom-input{
  background: rgba(57, 57, 57, 0.07);
  border: none;
  border-radius: 10px; 
  width: 300px;
}


.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 30px;
  padding-top: 20px;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #ac003e;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}
</style>