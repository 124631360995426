<template>
  <div class="about" id="dementie-information">
    <alert messageType="error" :message="errorMessage" @close="errorMessage = null" position="absolute" />
    <div class="parent clearfix">
      <div class="bg-illustration">
        <img :src="icon" alt="">
        <div class="burger-btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="login">
        <div class="container">
          <h1>Inlogpagina</h1>
          <p class="info">Hier kunt u inloggen met uw gebruikersnaam en wachtwoord.</p>
          <div class="login-form">
            <div class="form-group mb-4">
              <label 
              class="form-label" 
              for="gebruikersnaam">Gebruikersnaam</label>
              <input 
              aria-label="Typ je gebruikersnaam" 
              ref="usernameInput" 
              type="text" 
              v-model="user.username" 
              id="gebruikersnaam" 
              class="form-control input"
              :class="{ 'is-invalid': $v.user.username.$error}" 
              placeholder="Gebruikersnaam" 
              autocomplete="on"/>
              <div v-if="$v.user.username.$error" class="invalid-feedback">
                <span v-if="!$v.user.username.required">Gebruikersnaam is vereist</span>
              </div>
            </div>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">
            <div class="form-group mb-4">
              <label 
              class="form-label" 
              for="wachtwoord">Wachtwoord</label>
              <input 
              aria-label="Voer uw wachtwoord in" 
              v-if="showPassword" 
              type="text" 
              class="form-control input" 
              placeholder="Wachtwoord" 
              v-model="user.password"
              :class="{ 'is-invalid': $v.user.password.$error }" 
              id="wachtwoord" 
              autocomplete="on">
              <input v-else type="password" class="form-control input" placeholder="Wachtwoord" v-model="user.password"
              :class="{ 'is-invalid': $v.user.password.$error }" id="password1">
              <span v-if="showPassword" class='abb'><i class="fa fa-eye-slash" @click="toggleShow"></i></span> 
              <span v-else class='abb'><i class="fa fa-eye" @click="toggleShow"></i></span> 
              <div v-if="$v.user.password.$error" class="invalid-feedback">
                <span v-if="!$v.user.password.required">Wachtwoord is vereist</span>
              </div>
              <a class="modal-button" @click="showModal" @keyup.enter="handleLinkEnter" tabindex="0"
              >Wachtwoord vergeten?</a>
            </div>
          </div>
          <reset-password-modal  role="button" tabindex="0" :username="user.username" v-show="isModalVisible" @close="closeModal"/>
          <button aria-label="Inloggen" class="btn btn-secondary" @click="login()" @keydown.enter="handleButtonEnter" type="button" aria-pressed="false" name="InloggenButton" value="Inloggen">Inloggen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions , mapGetters } from 'vuex'
import Alert from '../components/Alert.vue'
import ResetPasswordModal from '../components/ResetPasswordModal.vue'
import icon from '@/assets/LoginIcon.svg'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Login',
  },
  components: { Alert, ResetPasswordModal },
  data () {
    return {
      icon:icon,
      showPassword: false,
      user: {
        username: null,
        password: null
      },
      errorMessage: null,
      isModalVisible: false
    }
  },
  validations: {
    user: {
      password: { required, minLength: minLength(1) },
      username: { required }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
  // focus on the first input element when the component is mounted
    this.$nextTick(() => {
      this.$refs.usernameInput.focus();
    });
  },
  methods: {
    ...mapActions(['checkLogin']),
    login () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        axios.post(`${process.env.VUE_APP_API_URL}/rest-auth/login/`, this.user).then(
          (response) => {
            localStorage.setItem('TOKEN_STORAGE_KEY', response.data.key)
            Object.assign(this.$http.defaults, { headers: { authorization: `Token ${response.data.key}` } })
            this.checkLogin(this)
          },
          (error) => {
            this.errorMessage = error.response.data.non_field_errors[0]
          }
        )
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    handleLinkEnter(event) {
    if (event.key === 'Enter') {
      this.showModal();
    }
  },
  handleButtonEnter(event) {

    if (event.key === 'Enter') {
      this.login();
    }
  }
  }
}
</script>

<style scoped>

.footer a {
  color: #FFF !important;
}

input, small {
  font-size: 12px;
}

.form-control{
  border-bottom:2px rgba(213, 218, 221, 1) solid !important;
  border: none;
  border-radius: 0px;
  font-size: 12px;
  margin-top: 10px;
}

.form-control:focus{
  outline: none !important;
  box-shadow: none;
  border-bottom:2px var(--color-1) solid !important;
}

.form-control:hover{
  outline: none !important;
  box-shadow: none;
  border-color: #747474 !important;
  background-color: #747474 !important;
}

.input {
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 68px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.input:focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: 2px var(--color-1) solid !important;
}

.input:hover {
  outline: none !important;
  box-shadow: none;
  border-color: #747474 !important;
  background-color: #747474 !important;
}

.form {
  width: 20%;
}

.title-wrapper {
  margin-bottom: 5vh;
}

.btn-secondary {
  background: var(--color-1);
  text-align: center;
  padding: 5px;
  font-weight: bold;
  height: 40px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 10px;
  width: 150px;
  max-width: 300px;
}

.btn-secondary:focus {
  outline: none !important;
  box-shadow: none !important;
  background: #e8f0fe;
  color: var(--color-1);
  border:2px rgb(20,66,115) solid !important;
}

.btn-secondary:hover {
  outline: none !important;
  box-shadow: none !important;
  background: #e8f0fe;
  color: var(--color-1);
  border:2px rgb(20,66,115) solid !important;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

body {
  background-color: #fff;
  font-family: Gotham;
  overflow-x: hidden;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  text-decoration: none;
}

img {
  border: none;
}

*:focus {
  outline: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.bg-illustration {
  position: relative;
  height: 100vh;
  width: 1194px;
  background: transparent no-repeat center center scroll;
  background-size: cover;
  float: left;
  -webkit-animation: bgslide 2.3s forwards;
          animation: bgslide 2.3s forwards;
}
.bg-illustration img {
  width: 248px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: auto;
  margin: 100px 0 0 120px;
}

@-webkit-keyframes bgslide {
  from {
    left: -100%;
    width: 0;
    opacity: 0;
  }
  to {
    left: 0;
    width: 1194px;
    opacity: 1;
  }
}

@keyframes bgslide {
  from {
    left: -100%;
    width: 0;
    opacity: 0;
  }
  to {
    left: 0;
    width: 1194px;
    opacity: 1;
  }
}


.login {
  max-height: 100vh;
  overflow-Y: auto;
  float: left;
  margin: 0 auto;
  width: calc(100% - 1194px);
}

.login .container {
  width: 505px;
  margin: 0 auto;
  position: relative;
}

.login .container h1 {
  margin-top: 100px;
  font-size: 35px;
  font-weight: bolder;
}

.login .container .login-form {
  margin-top: 80px;
}

.login .container .login-form form {
  display: -ms-grid;
  display: grid;
}



.button[type=submit] {
  background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
  border: none;
  margin-top: 124px;
  margin-bottom: 20px;
  width: 241px;
  height: 58px;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 20px;
}

.button[type=submit]:hover::after {
  opacity: 1;
}

.button[type=submit]::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 10px;
  opacity: 0;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(#09203f), to(#537895));
  background: -webkit-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: -o-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: linear-gradient(to top, #09203f 0%, #537895 100%);
}

.info{
  margin: 2rem 0 2rem 0;
}


@media only screen and (min-width: 1024px) and (max-width: 1680px) {
  .bg-illustration {
    width: 50%;
    -webkit-animation: none;
            animation: none;
  }

  .login {
    width: 50%;
  }
}
/* Display 12", iPad PRO Portrait, iPad landscape */
@media only screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }

  @-webkit-keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  .bg-illustration {
    float: none;
    background: transparent center center;
    background-size: cover;
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 100%;
    height: 190px;
    text-align: center;
  }
  .bg-illustration img {
    width: 100px;
    height: auto;
    margin: 20px auto !important;
    text-align: center;
  }
  .bg-illustration .burger-btn {
    left: 33px;
    top: 29px;
    display: block;
    position: absolute;
  }
  .bg-illustration .burger-btn span {
    display: block;
    height: 4px;
    margin: 6px;
    background-color: #fff;
  }
  .bg-illustration .burger-btn span:nth-child(1) {
    width: 37px;
  }
  .bg-illustration .burger-btn span:nth-child(2) {
    width: 28px;
  }
  .bg-illustration .burger-btn span:nth-child(3) {
    width: 20px;
  }

  .login {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .login .container {
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 85%;
    float: none;
  }
  .login .container h1 {
    font-size: 25px;
    margin-top: 40px;
  }
  .login .container .login-form {
    margin-top: 90px;
  }
  .input {
    height: 45px;
  }
  .button[type=submit] {
    height: 45px;
    margin-top: 100px;
  }
}

.modal-button {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-10);
}

.modal-button:hover {
  color: var(--color-14);
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.modal-button:focus {
  outline: none;
  box-shadow: none;
  color: var(--color-14);
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.abb{ 
  display: flex;
  align-items: center;
  position: absolute;
  top: 71%;
  right: 0;
  padding-right: 15px;
}

.form-label{
  display: inline-block;
  font-weight: bold;
  color: var(--color-1);
}

</style>
